<template>
  <div id="page-meal-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  busProducts.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : busProducts.length
                }}
                of {{ busProducts.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <router-link
          :to="{ path: '/bus-product/add' }"
          v-if="
            $store.state.AppActiveUser.userInfo.roles[0].permissions
              .map((permission) => permission.name)
              .includes('create_meal')
          "
        >
          <div
            class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
          >
            <span class="mr-2 leading-none">Add</span>
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          </div>
        </router-link>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="busProducts"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

// Store Module
import busProductStore from "@/store/bus-product";

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";

export default {
  metaInfo: {
    title: "Bus Product",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererActions,
  },
  data() {
    return {
      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
        },
        {
          headerName: "Brand",
          field: "brand.name",
        },
        {
          headerName: "Fleet Type",
          field: "fleet_type.name",
        },
        {
          headerName: "Product",
          field: "product.name",
        },
        {
          headerName: "Seat Arrangement",
          field: "seat_arrangement.name",
        },
        {
          headerName: "Total Seat",
          field: "total_seat",
        },
        {
          headerName: "Actions",
          field: "transactions",
          cellRendererFramework: "CellRendererActions",
          cellRendererParams: {
            getData: this.getData.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
      },
    };
  },

  computed: {
    busProducts() {
      return this.$store.state.busProduct.busProducts || [];
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridOptions.api.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.departmentFilter =
          { label: "All", value: "all" };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getData() {
      try {
        this.$vs.loading({
          type: "sound",
        });
        await this.$store.dispatch("busProduct/fetchBusProducts");
      } catch (error) {
        (err) => this.$catchErrorResponse(err);
      } finally {
        this.$vs.loading.close();
      }
    },
  },
  async created() {
    if (!busProductStore.isRegistered) {
      this.$store.registerModule("busProduct", busProductStore);
      busProductStore.isRegistered = true;
    }

    await this.getData();
  },
  async mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.api.sizeColumnsToFit();

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`;
    }
  },
};
</script>

<style lang="scss">
#page-meal-list {
  .meal-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-row .text-right {
  flex-direction: row-reverse;
}
</style>
