import axios from "@/axios.js";

export default {
  async fetchBusProducts({ commit }) {
    try {
      const { data } = await axios.get(`/shuttle/bus-product`);
      commit("setBusProducts", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchBusProduct({ commit }, params) {
    try {
      const { data } = await axios.get(`/shuttle/bus-product/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeBusProduct({ commit }, params) {
    try {
      const { data } = await axios.post(`/shuttle/bus-product`, params);
      return { success: true };
    } catch (error) {
      return error;
    }
  },
  async updateBusProduct({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/shuttle/bus-product/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeBusProduct({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/shuttle/bus-product/${params}`);
      dispatch("fetchBusProducts");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
